import React from "react"
import "./blog-preview.scss"
import Link from "gatsby-link"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import TruncateMarkup from "react-truncate-markup"
import { explorersMap } from "../helpers/color"
import Card from "react-bootstrap/Card"

const BlogPreview = ({ post, ...otherProps }) => {
  return (
    <Link to={`/blog/${post.slug}`} className="wrapperBlogPost">
      {post.heroImage && post.heroImage.gatsbyImageData && (
        <GatsbyImage
          image={post.heroImage.gatsbyImageData}
          className="card-img-top"
          title={post.title}
        />
      )}
      <Card.Body className="bodyBlogPost">
        {post.tags && post.tags.length > 0 && (
          <div className="card-top-row">
            <div className={`tag ${explorersMap(post?.tags[0])} blog-tag`}>
              {post.tags[0]}
            </div>
            {/* <div className="published-date">{post.publishDate}</div> */}
          </div>
        )}
        <Card.Title className="titleBlogPost">
          <TruncateMarkup lines={2}>
            <h5>{post.title}</h5>
          </TruncateMarkup>
        </Card.Title>
        <p className="subtitle">
          <TruncateMarkup lines={2}>
            <span>{post.subtitle}</span>
          </TruncateMarkup>
        </p>
      </Card.Body>
    </Link>
  )
}

export default BlogPreview
